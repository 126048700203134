import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import moment from 'moment-timezone';
import './addEvent.css';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LoginPage from './Login/LoginPage.js';

const AddEvent = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    date: '',
    about: '',
    image: null,
  });
  const [error, setError] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check persisted login status on component mount
    const loginStatus = localStorage.getItem('loggedIn');
    if (loginStatus) {
      setLoggedIn(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const date = new Date(formData.date);
    const pstDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

    const data = new FormData();
    data.append('title', formData.title);
    data.append('subtitle', formData.subtitle);
    data.append('date', pstDate.toISOString());
    data.append('about', formData.about);
    data.append('image', formData.image);

    try {
      await axios.post('http://localhost:4000/api/upload-latest-event', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setError('');
      navigate("/upcoming");
      toast.success('Event added successfully');
    } catch (err) {
      setError('Failed to add event');
      toast.error('Failed to add event');
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).tz('America/Los_Angeles').format('YYYY-DD-MM hh:mm:ss A');
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        {!loggedIn && <LoginPage setLogIn={setLoggedIn} />}
        {loggedIn && (
          <>
            <form id="event-form" onSubmit={handleSubmit}>
              <h1>Add New Event</h1>
              <div className="form-group">
                <label htmlFor="title">Title:</label>
                <input type="text" id="title" name="title" onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="subtitle">Subtitle:</label>
                <input type="text" id="subtitle" name="subtitle" onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date:</label>
                <input type="datetime-local" id="date" name="date" onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="event-image">Event Image:</label>
                <input type="file" id="event-image" name="image" onChange={handleChange} accept="image/*" required />
              </div>
              <div className="form-group">
                <label htmlFor="about">About Event (Markdown):</label>
                <textarea id="about" name="about" rows="10" onChange={handleChange} required></textarea>
              </div>
              <button type="submit">Add Event</button>
              {error && <p className="error">{error}</p>}
            </form>

            <div id="preview" className="preview">
              <h2>Preview</h2>
              <div id="event-preview">
                <h3>{formData.title}</h3>
                <h4>{formData.subtitle}</h4>
                <p>{formData.date ? formatDate(formData.date) : ''}</p>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{formData.about}</ReactMarkdown>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddEvent;