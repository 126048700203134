import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Analytics = () => {
  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;
    ReactGA.initialize(measurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return null; // This component doesn't need to render anything
};

export default Analytics;
