// PastEvents.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PastEvents.css'; // Import the CSS file for styling
import CommonSkeleton from '../components/Skeleton/CommonSkeleton';

const PastEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-all-past-events`);
        setEvents(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching past events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="past-events-container">
      <h2>Past Events</h2>
      {loading ? (
        <CommonSkeleton />
      ) : (
        events.map((event) => (
          <div key={event._id} className="event-box">
            <h2 className="event-title">{event.title}</h2>
            <p className="event-subtitle">{event.subtitle}</p>
            <p className="event-date">{new Date(event.date).toLocaleDateString()}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default PastEvents;
