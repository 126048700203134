import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const CommonSkeleton = () => {
  return (

    <div className="skeleton-container">
      <Skeleton height={200} width={600} />
      <Skeleton height={200} width={600} />
      <Skeleton height={200} width={600} />
      
    </div>

   
  )
}

export default CommonSkeleton