import React from 'react'
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { MdOutlineInsertPageBreak } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { FaTrophy } from "react-icons/fa";
const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className='upcoming-page'>
      <div className='page-header'>
        <h3 className='title'> Welcome to ResearchPro </h3>

        <p className='subtitle'>Your Trusted Partner in Research excellence.
        </p>
        <button className='highlight-button' onClick={() => navigate("/upcoming")}> Start Learning </button>
      </div>



      <div className="achivements">

        <p> We understand the unique challenges and demands that come with pursuing advanced academic research. Our series of webinars is designed to cover a wide range of research-related topics, providing you with in-depth knowledge and practical insights to elevate your academic journey.</p>
        <div className="highlights">
          <div className="cards">
            <MdOutlineInsertPageBreak size={50} className='image' color='#fbb83b'/>
            <p className='card-number'>5000+</p>
            <h5 className='card-text'> Scholars assists</h5></div>

          <div className="cards">
            <IoIosPeople size={50} className='image' color='#fbb83b' />
            <p className='card-number'>7+</p>
            <h5 className='card-text'> Years of Experience</h5></div>
          <div className="cards">
            <FaTrophy size={50} className='image' color='#fbb83b'/>
            <p className='card-number'>120+</p>
            <h5 className='card-text'> Countries Reached</h5></div>


        </div>
        <button className='highlight-button' onClick={() => navigate("/upcoming")}> See Upcoming Events </button>
      </div>

      <div className="testimonials">
        <h1 className="testimonials-header">Testimonials </h1>
        <div className="testimonials-items">


          <div className="cards">
            <p className='quotes'>"I’ve attended several webinars hosted by ResearchPro, and each one has exceeded my expectations. The interactive format and expert speakers make complex topics accessible and engaging. It’s a fantastic platform for any researcher!"</p>
            <p>— Maria, Postdoctoral Researcher</p>
          </div>
          <div className="cards">
            <p className='quotes'>"I’ve found ResearchPro’s webinars to be incredibly valuable. The expert-led sessions offer deep dives into critical research areas and provide actionable advice that has directly improved my research outcomes"</p>
            <p>— Sophia, Postdoctoral Fellow</p>
          </div>
          <div className="cards">
            <p className='quotes'>``The resources and webinars offered by ResearchPro are unparalleled. The platform has been a fantastic aid in my research, providing expert guidance and a wealth of knowledge that has greatly enhanced my academic performance.``</p>
            <p>— Olivia Wilson, Master's Student</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage